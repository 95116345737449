/* page sidebar css */

.cd_page_sidebar {
	background: #252f47;
	max-width: 250px;
	width: 100%;
	min-height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	max-height: 469px;
	overflow-y: auto;
	transition: all 0.3s ease-in-out;
	z-index: 99999999;
}

.cd_page_sidebar::-webkit-scrollbar {
	width: 0;
}

.cd_page_sidebar .cd_page_sidebar_content {
	min-height: 100vh;
	padding-bottom: 100px;
	position: relative;
}

.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_logo a {
	max-width: 250px;
	display: block;
}

.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_logo {
	padding: 23px 25px 28px;
	position: relative;
}

.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_logo .cd_page_sidebar_toggle_btn {
	top: 15px;
	right: 20px;
	left: auto;
}

.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_nav .cd_page_sidebar_items .cd_page_sidebar_link {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #828cae;
	padding: 17px 25px 17px 35px;
	text-decoration: none;
}

.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_nav .cd_page_sidebar_items .cd_page_sidebar_link svg {
	margin-right: 32px;
}

.cd_page_sidebar[title='dashboard']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='dashboard']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='portfolio']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='portfolio']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='wallets']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='wallets']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='history']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='history']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='keyManager']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='keyManager']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='tokens']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='tokens']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='settings']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='settings']
	.cd_page_sidebar_link::before,
.cd_page_sidebar[title='accountsupport']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='accountsupport']
	.cd_page_sidebar_link::before {
	content: '';
	position: absolute;
	left: -6px;
	top: 50%;
	transform: translateY(-50%);
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background: #53b9ea;
	box-shadow: 0px 0px 5px #1f1c45;
}

.cd_page_sidebar[title='dashboard']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='dashboard']
	.cd_page_sidebar_link::after,
.cd_page_sidebar[title='wallets']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='wallets']
	.cd_page_sidebar_link::after,
.cd_page_sidebar[title='history']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='history']
	.cd_page_sidebar_link::after,
.cd_page_sidebar[title='tokens']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='tokens']
	.cd_page_sidebar_link::after,
.cd_page_sidebar[title='keyManager']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='keyManager']
	.cd_page_sidebar_link::after {
	content: '';
	position: absolute;
	left: -17px;
	top: 50%;
	transform: translateY(-50%);
	width: 34px;
	height: 34px;
	border-radius: 34px;
	background: radial-gradient(
		43.97% 43.97% at 50% 51.18%,
		rgba(0, 196, 255, 0.0001) 0%,
		rgba(50, 196, 246, 0.264719) 35.94%,
		rgba(83, 185, 234, 0.0001) 100%
	);
}

.cd_page_sidebar[title='tokens']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='tokens']
	.cd_page_sidebar_link,
.cd_page_sidebar[title='dashboard']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='dashboard']
	.cd_page_sidebar_link,
.cd_page_sidebar[title='portfolio']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='portfolio']
	.cd_page_sidebar_link,
.cd_page_sidebar[title='wallets']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='wallets']
	.cd_page_sidebar_link,
.cd_page_sidebar[title='history']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='history']
	.cd_page_sidebar_link,
.cd_page_sidebar[title='keyManager']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='keyManager']
	.cd_page_sidebar_link {
	color: #53b9ea;
}

.cd_page_sidebar[title='dashboard']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='dashboard']
	.cd_page_sidebar_link
	svg
	rect,
.cd_page_sidebar[title='accountsupport']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='accountsupport']
	.cd_page_sidebar_link
	svg
	path,
.cd_page_sidebar[title='keyManager']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='keyManager']
	.cd_page_sidebar_link
	svg
	path,
.cd_page_sidebar[title='history']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='history']
	.cd_page_sidebar_link
	svg
	path,
.cd_page_sidebar[title='tokens']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='tokens']
	.cd_page_sidebar_link
	svg
	path,
.cd_page_sidebar[title='wallets']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='wallets']
	.cd_page_sidebar_link
	svg
	path {
	fill: #53b9ea;
}

.cd_page_sidebar[title='keyManager']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='keyManager']
	.cd_page_sidebar_link
	svg
	path
	+ path {
	fill: #252f47;
}

.cd_page_sidebar[title='portfolio']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='portfolio']
	.cd_page_sidebar_link
	svg
	path,
.cd_page_sidebar[title='settings']
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items[title='settings']
	.cd_page_sidebar_link
	svg
	path {
	stroke: #53b9ea;
}

.cd_page_sidebar
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items
	.cd_page_sidebar_link
	.cd_page_sidebar_notification_dot {
	width: 6px;
	height: 6px;
	display: block;
	background: linear-gradient(180deg, #ff40a5 0%, #e14cd1 100%);
	opacity: 0.8;
	border-radius: 10px;
	box-shadow: 0px 0px 5px #e01066;
	margin-left: auto;
}

.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_toggle_icon {
	position: absolute;
	right: 20px;
	bottom: 69px;
	background: transparent;
	border: 0;
	padding: 0;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: 0;
	transform: rotate(180deg);
}

.cd_page_sidebar.cd_hide_sidebar .cd_page_sidebar_content .cd_page_sidebar_toggle_icon {
	transform: rotate(0);
}

.cd_page_sidebar
	.cd_page_sidebar_content
	.cd_page_sidebar_nav
	.cd_page_sidebar_items
	.cd_page_sidebar_link
	.cd_pagesidebar_text {
	display: block;
}

.cd_page_sidebar_toggle_btn {
	display: block;
	padding: 0;
	width: 35px;
	height: 35px;
	position: absolute;
	top: 44px;
	left: 20px;
	color: #fff;
	background: linear-gradient(224.09deg, rgba(61, 71, 106, 0.45) 0%, rgba(61, 71, 106, 0.49) 98.47%);
	border: 1px solid #7c86a0;
	border-radius: 4px;
	outline: 0 !important;
}

.cd_page_sidebar_toggle_btn svg {
	width: 24px;
	height: 24px;
}

.cd_all_pages_content .cd_page_sidebar[title='login'] ~ .cd_page_sidebar_toggle_btn,
.cd_all_pages_content .cd_page_sidebar[title=''] ~ .cd_page_sidebar_toggle_btn {
	display: none;
}

@media (min-width: 992px) {
	/* hide show sidebar */
	.cd_page_sidebar.cd_hide_sidebar {
		max-width: 90px;
	}

	.cd_page_sidebar.cd_hide_sidebar
		.cd_page_sidebar_content
		.cd_page_sidebar_nav
		.cd_page_sidebar_items
		.cd_page_sidebar_link
		svg {
		margin: 0 auto;
	}

	.cd_page_sidebar.cd_hide_sidebar
		.cd_page_sidebar_content
		.cd_page_sidebar_nav
		.cd_page_sidebar_items
		.cd_page_sidebar_link
		.cd_pagesidebar_text,
	.cd_page_sidebar.cd_hide_sidebar
		.cd_page_sidebar_content
		.cd_page_sidebar_nav
		.cd_page_sidebar_items
		.cd_page_sidebar_link
		.cd_page_sidebar_notification_dot,
	.cd_page_sidebar.cd_hide_sidebar .cd_main_logo,
	.cd_page_sidebar .cd_mini_sidebar_logo,
	.cd_page_sidebar_toggle_btn {
		display: none;
	}

	.cd_page_sidebar.cd_hide_sidebar
		.cd_page_sidebar_content
		.cd_page_sidebar_nav
		.cd_page_sidebar_items
		.cd_page_sidebar_link {
		padding: 25px 12px;
		justify-content: center;
	}

	.cd_page_sidebar.cd_hide_sidebar .cd_mini_sidebar_logo {
		display: block;
		margin: 0 auto;
	}

	.cd_page_sidebar.cd_hide_sidebar .cd_page_sidebar_content .cd_page_sidebar_logo {
		padding: 0;
	}

	.cd_page_sidebar.cd_hide_sidebar .cd_page_sidebar_content .cd_page_sidebar_logo a {
		padding: 25px 12px;
	}

	.cd_page_sidebar.cd_hide_sidebar
		.cd_page_sidebar_content
		.cd_page_sidebar_nav
		.cd_page_sidebar_items
		.cd_page_sidebar_link:hover {
		background: linear-gradient(224.09deg, rgba(61, 71, 106, 0.45) 0%, rgba(61, 71, 106, 0.49) 98.47%);
	}

	.cd_light_theme_active
		.cd_page_sidebar.cd_hide_sidebar
		.cd_page_sidebar_content
		.cd_page_sidebar_nav
		.cd_page_sidebar_items
		.cd_page_sidebar_link:hover {
		background: #eff0f2;
	}
}

@media (max-width: 991px) {
	.cd_page_sidebar {
		left: -100%;
	}

	.cd_page_sidebar.cd_hide_sidebar {
		left: 0;
		max-width: 270px;
	}

	.cd_page_sidebar.cd_hide_sidebar .cd_mini_sidebar_logo,
	.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_toggle_icon {
		display: none;
	}

	.cd_page_sidebar .cd_page_sidebar_content .cd_page_sidebar_logo {
		padding: 55px 25px 28px;
	}
	.cd_page_sidebar .cd_page_sidebar_content {
		padding-bottom: 50px;
	}
}

@media (max-width: 767px) {
	.cd_page_sidebar_toggle_btn {
		left: 15px;
	}
}
